<template>
  <div>
    <img
      id="profile-img"
      src="@/assets/mnwd_logo.png"
      class="auth-card-img"
    />
    <h1>BAT tool has been temporarily disabled</h1>

  </div>
</template>

<script>
export default {
  name: "Disabled",
};
</script>
